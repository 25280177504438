.challangeFrame {
    background-color: #003153;
    max-width: 900px;
    border-radius: 30px;
    margin: 1em auto;
    border-top: 2px solid #ff9505;
    padding-top: 0.5em;
}
.URLinput {
    padding: 5px;
    height: 2em;
    max-width: 20em;
    border-radius: 30px;
    text-align: center;
    margin-top: 0.5em;
    overflow: hidden;
}

.copybtn {
    font-size: medium;
    height: 1.2em;
    width: 6em;
}

/*  #Mobile 
================================================== */
@media only screen and (max-device-width: 1000px) {

    .URLinput {
        padding: 5px;
        height: 2em;
        max-width: 40em;
        border-radius: 30px;
        text-align: center;
        margin-top: 0.5em;
        overflow: scroll;
    }
}