.card {
    position: relative;
    background-image: url("cardimg.png");
    background-position: center center;
    background-repeat:   no-repeat;    
    background-color: #004996;    
    border-radius: 10px;
    box-shadow: 4px 10px 34px -15px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 10px 34px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 10px 34px -15px rgba(0,0,0,0.75);
}

.cardList{
    background-image: none;    
    max-width: 100%;
    min-height: 2.5em;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;
}

.cardGrid{
    background-size: 75%;
    height: 13em;
    width: 6.8vw;
    margin: 1em 0.3em 1em 0.3em;
}

.card:active{
    background-color: #ff9505;
}

.right {
    background-color: green;
}
.wrong {
    background-color: darkred;
}

.name{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 1em auto;
    font-size: 1em;
    word-break: break-word;
    overflow: hidden;
}

.born {
    margin-top: 0.3em;
}

.hide{
    display: none;
}

/*  #Mobile 
================================================== */

@media only screen and (max-device-width: 1000px) {

  }
  
