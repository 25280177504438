.cardspace{
  display: flex;
}

.cardspaceGrid{
    height: 15em;
  }
  
.cardspaceList{ 
  display: inline-block;
  width: 100%;
  padding: 0.3em;
  max-height: 6em;
  min-height: 3em;
}

.droppZone{
  background-color: #00000050;
  justify-content: space-evenly; 
  vertical-align: middle;
  align-content: center;   
}

.droppZoneGrid {
  display: flex; 
  max-width: 130em;   
  margin: 0 auto; 
  border-radius: 20px;

}

.droppZoneList {
  max-width: 80rem;
  border-radius: 20px; 
  padding: 10px;
  display: block;   
  margin: 0 auto ;   
}

.droppZoneInfo{
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
}

.droppZoneInfoTop {
  background-color: #00000050;
  border-radius: 20px 20px 0 0;
  padding: 5px 5px 0 5px;
  width: 22em; 
  margin: 1em 0 0 0;
}
.droppZoneInfoBottom {
  background-color: #00000050;
  border-radius: 0 0 20px 20px;
  padding: 0 5px 5px 5px;
  margin: 0 auto 20px auto;
  width: 22em;
}

.aftergame-container{
  padding: 1em;
  background-color: #00000050;
  border-radius: 20px;
  margin: 1em 1em;
  min-width: 20em;
  max-width: 50em;
}

#finishBtn {
  width: 15rem;
  height: 5rem;
}

#newgameBtn {
  width: 10rem;
  height: 3rem;
}

.viewBtn {
  background-color: #4180b4;
  border-radius: 30px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  height: 2em;
  width: 8em;
  border: none;
  text-align: center;
  margin: 0 1em;
}

.viewBtn:hover {
  background-color: #ffb41f;
}

.settings-container{
  display: flex;
  justify-content: center;
}

  /*  #Mobile 
================================================== */
@media only screen and (max-device-width: 1000px) {
  

}

